import { redirect } from '../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export function initServerFetchNumValidation(phoneNumber) {
    // VARS
    const form = document.querySelector('[data-form-popup]');
    const popup = document.querySelector('[data-popup-location]');
    if (!popup && !form) return;
    const popupSuccessBlock = popup.querySelector('[data-popup-success-block]');
    const popupLocation = popup.querySelector('[data-popup-location]');
    // const validationUrl = popup.getAttribute('data-url');
    // const data = { number: phoneNumber, token: csrfToken };
    // const csrfToken = popup.getAttribute('data-csrf-token');

    // INITS
    setTimeout(() => {
        showSuccessPopupAndSubmit(1300);
    }, 3300);

    /* Commented because validation on the backend does not work due to limits and we will redirect to the checkout in any case. */
    /*fetch(validationUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            return response.json();
        })
        .then(() => {
            setTimeout(() => {
                showSuccessPopupAndSubmit(1300);
            }, 3300);
        })
        .catch(function () {
            showSuccessPopupAndSubmit(3300);
        });*/

    // FUNCTIONS
    function showSuccessPopupAndSubmit(timerSubmit) {
        setTimeout(() => {
            popupSuccessBlock.classList.add('success');
            popupLocation.classList.add('success');

            if (form) {
                setTimeout(() => {
                    if (document.querySelector('[data-plan-form]').dataset.planForm === 'submit') {
                        form.submit();
                    } else {
                        redirect(form);
                    }
                }, 500);
            }
        }, timerSubmit);
    }
}

export function initSubmitForm() {
    // VARS
    const form = document.querySelector('[data-form-popup]');
    const popup = document.querySelector('[data-popup-location-split]');
    if (!popup && !form) return;

    setTimeout(() => {
        const button = document.querySelector('[data-split-submit-button]');
        button.addEventListener('click', function (event) {
            event.preventDefault();
            if (document.querySelector('[data-plan-form]').dataset.planForm === 'submit') {
                form.submit();
            } else {
                redirect(form);
            }
        });
    }, 7100);
}
