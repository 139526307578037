import mixpanel from 'mixpanel-browser';

export function initTrackOnAnimation() {
    const splitGroup = document.querySelector('[name="split_group"]')?.value;
    const splitVariant = document.querySelector('[name="split_variant"]')?.value;
    const splitLocale = document.querySelector('[name="split_locale"]')?.value;

    mixpanel.people.set({
        split_group: splitGroup,
        split_variant: splitVariant,
        split_locale: splitLocale,
        split_start: window.location.href,
    });
}
