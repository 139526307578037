import lottie from 'lottie-web';
import { initTrackOnAnimation } from './initTrackOnAnimation';

export const initAnimationOnSearchPhone = () => {
    // VARS
    const container = document.querySelector('[data-location]');
    const header = document.querySelector('[data-header]');
    const locationPopup = document.querySelector('[data-popup-location]');
    const progressLine = document.querySelector('[data-progress-line]');
    const animationUs = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationUs;
    const animationFr = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationFr;
    const animationDe = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationDe;
    const animationEs = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationEs;
    const animationIt = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationIt;
    const animationPt = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationPt;
    const animationTr = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationTr;
    const animationAe = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationAe;
    const animationWorld = document.querySelector('[data-animation-location-us]')?.dataset.animationLocationWorld;
    if (!container) return;
    let location = container.getAttribute('data-location');
    const countries = ['us', 'fr', 'de', 'es', 'it', 'pt', 'tr', 'ae'];
    let fetchUrl;

    // INITS
    if (!countries.includes(location)) {
        location = 'world';
    }

    switch (location) {
        case 'us':
            fetchUrl = `${window.location.origin}${animationUs}`;
            break;
        case 'fr':
            fetchUrl = `${window.location.origin}${animationFr}`;
            break;
        case 'de':
            fetchUrl = `${window.location.origin}${animationDe}`;
            break;
        case 'es':
            fetchUrl = `${window.location.origin}${animationEs}`;
            break;
        case 'it':
            fetchUrl = `${window.location.origin}${animationIt}`;
            break;
        case 'pt':
            fetchUrl = `${window.location.origin}${animationPt}`;
            break;
        case 'tr':
            fetchUrl = `${window.location.origin}${animationTr}`;
            break;
        case 'ae':
            fetchUrl = `${window.location.origin}${animationAe}`;
            break;
        default:
            fetchUrl = `${window.location.origin}${animationWorld}`;
    }

    container.textContent = '';

    fetch(fetchUrl)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            animateMap(data);
            animateProgressBar();
        });

    function animateMap(locations) {
        lottie.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: locations[location.toLowerCase()],
        });
    }

    // FUNCTION
    function animateProgressBar() {
        header.classList.add('popup-console');
        locationPopup.classList.remove('hidden');
        progressLine.classList.add('progress__line--animated');

        initTrackOnAnimation();
    }
};

export const initAnimationOnSearchPhoneSplit = () => {
    const locationPopup = document.querySelector('[data-popup-location-split]');
    locationPopup.classList.remove('hidden');

    initTrackOnAnimation();
};
